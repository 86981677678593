import React from 'react';
import './landing.css';
import TopSliderScreen from '../TopSliderScreen/TopSliderScreen';
import ProductTable from '../ProductTable/ProductTable';
import About from '../About/About';
import Advantage from '../Advantage/Advantage';
import Technology from '../Technology/Technology';
import Comparison from '../Comparison/Comparison';
import Quality from '../Quality/Quality';
import Partners from '../Partners/Partners';
import Checkout from '../Checkout/Checkout';
import Footer from '../Footer/Footer';

const Landing = () => {
    return (
        <div className="landing-wrapper">
            <div style={{ overflowX: 'hidden'}}>
            <TopSliderScreen />
                <ProductTable />
                <About />
                <Advantage />
                <Technology />
                <Comparison />
                <Quality />
                <Partners />
                <Checkout />
            <Footer />
            </div>

        </div>
    );
}

export default Landing;
