import React from 'react';
import './quality.css';
import Proof1 from "../../assets/img/serts/sert1.png"
import Proof2 from "../../assets/img/serts/sert2.png"
import Proof3 from "../../assets/img/serts/sert3.png"

const Quality = () => {
    return (
        <div className="quality-wrapper" id="quality">
            <div className="container">
                <div className='quality-header'>Качество продукции</div>
                <div className='quality-certificates'>
                    <div className='item'>
                        <a target='_blank' href="/files/sert/Svidetelstvo_o_gos_registracii.pdf" title=""><img src={Proof1} alt="" /></a>
                        <p>Свидетельство о<br/>государственной регистрации</p>
                    </div>
                    <div className='item'>
                        <a target='_blank' href="/files/sert/Sertificat_sootvetstvia1.pdf" title=""><img src={Proof2} alt="" /></a>
                        <p>Сертификат соответствия</p>
                    </div>
                    <div className='item'>
                        <a target='_blank' href="/files/sert/Sertificat_sootvetstvia2.pdf" title=""><img src={Proof3} alt="" /></a>
                        <p>Сертификат соответствия</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quality;
