import React, { useState, useEffect } from 'react';
import './header.css';
import LogoWhite from "../../assets/img/logo-white.svg"
import Phone from "../../assets/img/ico_phone.svg"
import TopMenu from '../TopMenu/TopMenu';

const Header = () => {
    return (
        <div className="header-wrapper">
            <div className="container">
                <img src={LogoWhite} alt="logo-white" className='img-logo' />
                <span className='top-link'>Химзавод <b>Альянс</b></span>
                <TopMenu/>
                <span className="phonenumber"><a href="tel:+73422429942"><img src={Phone} alt="number-phone"/>(342) 242-99-42</a></span>
            </div>
        </div>
    );
}

export default Header;
