import React from "react";
import './productTable.css';
import ProductComponent from "../Product/Product";
import ProductImg1 from "../../assets/img/prod/prod1.png";
import ProductImg2 from "../../assets/img/prod/prod2.png";
import ProductImg3 from "../../assets/img/prod/prod3.png";
import ProductImg4 from "../../assets/img/prod/prod4.png";
import ProductImg5 from "../../assets/img/prod/prod5.png";
import ProductImg7 from "../../assets/img/prod/prod7.png";
import ProductImg8 from "../../assets/img/prod/prod8.png";
import ProductImg10 from "../../assets/img/prod/prod10.png";
import ProductImg11 from "../../assets/img/prod/prod11.png";


const ProductTable = () => {

    const products = [
        {
            id: 1, name: "Ледоруб ЭКО",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `10 кг.`,
            text: `Противогололедный реагент в удобной для хранения пластиковой таре от 5 до 10кг. Применяется при температуре до -25°С. Время действия — от 30 минут. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При использовании по инструкции не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg1
        },
        {
            id: 2, name: "Ледоруб ЭКО",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `25 кг.`,
            text: `Противогололедный реагент в мешках по 25кг. Применяется при температуре до -25°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg2
        },
        {
            id: 3, name: "Ледоруб ЭКО",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `1 т.`,
            text: `Противогололедный реагент в бигбэгах по 1т. Применяется при температуре до -25°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. Может применяться на любых дорогах с твердым покрытием. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg3
        },
        {
            id: 4, name: "Ледоруб Н",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -16°С`,
            compound3: `10 кг.`,
            text: `Противогололедный реагент в удобной для хранения пластиковой таре от 5 до 10кг. Применяется при температуре до -16°С. Время действия — от 30 минут. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При использовании по инструкции не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg4
        },
        {
            id: 5, name: "Ледоруб Н",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -16°С`,
            compound3: `25 кг.`,
            text: `Противогололедный реагент в мешках по 25кг. Применяется при температуре до -16°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg5
        },
        {
            id: 6, name: "Ледоруб Н",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -16°С`,
            compound3: `1 т.`,
            text: `Противогололедный реагент в бигбэгах по 1т. Применяется при температуре до -16°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. Может применяться на любых дорогах с твердым покрытием. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg3
        },
        {
            id: 7, name: "Ледоруб ЭКО+",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `10 кг.`,
            text: `Противогололедный реагент в удобной для хранения пластиковой таре от 5 до 10кг. Применяется при температуре до -25°С. Время действия — от 30 минут. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При использовании по инструкции не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg7
        },
        {
            id: 8, name: "Ледоруб ЭКО+",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `25 кг.`,
            text: `Противогололедный реагент в мешках по 25кг. Применяется при температуре до -25°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg8
        },
        {
            id: 9, name: "Ледоруб ЭКО+",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: хлористый натрий, хлористый кальций`,
            compound2: `Эффективное действие от 0 до -25°С`,
            compound3: `1 т.`,
            text: `Противогололедный реагент в бигбэгах по 1т. Применяется при температуре до -25°С. Время воздействия от 30 минут. Полностью растворим. Хранится в упаковке в течение всего сезона.`,
            text2: `Не оставляет грязи на дорогах. Может применяться на любых дорогах с твердым покрытием. При эксплуатации согласно инструкции, не вредит дорожному покрытию, обуви, автошинам, домашним животным.`,
            img: ProductImg3
        },
        {
            id: 10, name: "Кальций хлористый",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: Кальций хлористый технический`,
            compound2: ``,
            compound3: ``,
            text: `Состав: Кальций хлористый технический`,
            text2: ``,
            img: ProductImg10
        },
        {
            id: 11, name: "Галит/соль техническая",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Состав: Галит/соль техническая`,
            compound2: ``,
            compound3: ``,
            text: `Состав: Галит/соль техническая`,
            text2: ``,
            img: ProductImg11
        },
        {
            id: 12, name: "ПГМ по вашему ТЗ",
            icons: { snow: true, people: true, car: true, car2: true, eco: true },
            compound: `Наш завод может изготовить противогололедный материал по вашему техническому заданию`,
            compound2: ``,
            compound3: ``,
            text: `Наш завод может изготовить противогололедный материал по вашему техническому заданию`,
            text2: ``,
            img: ProductImg3
        }
    ];
    return (
        <div className="product-table-wrapper" id="table">
            <div className="container">
                <div className="product-table-header">Противогололёдный реагент нового поколения</div>
                <div className="product-table-products">
                    {products.map(product => <ProductComponent key={product.id} product={product} />)}
                </div>
            </div>
        </div>
    );
}

export default ProductTable;
