import React from 'react';
import './buyButton.css';

export interface Props {
    text: string;
}

const BuyButtonCircle: React.FC<Props> = props => {
    return (
        <div className="buy-button-wrapper circle">
            <button>{props.text}</button>
        </div>
    );
}

export default BuyButtonCircle;
