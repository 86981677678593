import React from 'react';
import './buyButton.css';

export interface Props {
    text: string;
}

const BuyButtonCircleDiv: React.FC<Props> = props => {
    return (
        <div className="buy-button-wrapper circle">
            <div>{props.text}</div>
        </div>
    );
}

export default BuyButtonCircleDiv;
