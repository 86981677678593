import React, {useRef, useState, useEffect} from 'react';
import { Link } from 'react-scroll';
import './topMenu.css';
import Catalog from "../../assets/img/catalog.svg";
import Cube from "../../assets/img/cube.svg";
import Info from "../../assets/img/info.svg";
import Like from "../../assets/img/like.svg";
import Partners from "../../assets/img/partners.svg";
import ProdCart from "../../assets/img/prod-cart.svg";
import BurgerMenu from "../../assets/img/burger-menu.svg"
import BurgerMenuGreen from "../../assets/img/burger-menu-green.svg"

const TopMenu = () => {

    const rootEl = useRef<HTMLDivElement>(null);
    const [isShow, setIsShow] = useState(false);
    const handleChange = () => {
        setIsShow(!isShow);
    }
    useEffect(() => {
      const onClick = (e: MouseEvent) => {
        if (rootEl.current) {
            rootEl.current.contains((e.target as Element)) || setIsShow(false);
        }
      }
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <div ref={rootEl} className="top-menu-wrapper-global">
            <img src={isShow ? BurgerMenuGreen : BurgerMenu} onClick={handleChange} alt="burger-menu" className='burger-menu'/>
            <div className={isShow ? 'top-menu-wrapper active' : 'top-menu-wrapper'}>
                <div className='item-menu-wrap'>
                    <div className='subitem-menu'>
                        <img src={Catalog} alt="Catalog" />
                        <div>
                            <Link to="table" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>Каталог</h3>
                                <p>Выберите решение для ваших задач</p>
                            </Link>
                        </div>
                    </div>
                    <div className='subitem-menu'>
                        <img src={Cube} alt="Cube" />
                        <div>
                            <Link to="quality" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>Качество продукции</h3>
                                <p>Свидетельства и&nbsp;сертификаты</p>
                            </Link>
                        </div>
                    </div>
                    <div className='subitem-menu'>
                        <img src={Info} alt="Info" />
                        <div>
                            <Link to="about" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>О компании</h3>
                                <p>Химзавод &laquo;Альянс&raquo;&nbsp;&mdash; динамично развивающееся предприятие</p>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='item-menu-wrap'>
                    <div className='subitem-menu'>
                        <img src={Like} alt="Like" />
                        <div>
                            <Link to="advantage" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>Преимущества</h3>
                                <p>Продукция &laquo;Ледоруб&raquo; прошла испытания и&nbsp;доказала свою эффективность</p>
                            </Link>
                        </div>
                    </div>
                    <div className='subitem-menu'>
                        <img src={Partners} alt="Partners" />
                        <div>
                            <Link to="partners" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>Партнёрам</h3>
                                <p>Если вы хотите стать нашим партнёром</p>
                            </Link>
                        </div>
                    </div>
                    <div className='subitem-menu'>
                        <img src={ProdCart} alt="ProdCart" />
                        <div>
                            <Link to="form" duration={500} smooth={true} onClick={() => setIsShow(false)}>
                                <h3>Оформить заказ</h3>
                                <p>Если вы&nbsp;хотите оформить заказ или запросить изготовление продукции</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopMenu;
