import React from 'react';
import './buyButton.css';

const BuyButton = () => {
    return (
        <div className="buy-button-wrapper">
            <button>Купить</button>
        </div>
    );
}

export default BuyButton;
