import React from 'react';
import { useEffect, useState } from 'react';
import './modalOrder.css';
import BuyButtonCircle from '../../Button/buyButtonCircle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { message } from 'antd';

export interface Props {
    prodname: string;
    show: boolean;
    onChange: () => void;
    openModal: boolean;
}

const ModalOrder = (props: Props) => {
    const [nameError, setNameError] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [mailError, setMailError] = useState('');
    const [prodError, setProdError] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [prod, setProd] = useState(props.prodname);
    const [disabled, setDisabled] = useState(true);
    const [nameDirty, setNameDirty] = useState(false);
    const [companyDirty, setCompanyDirty] = useState(false);
    const [prodDirty, setProdDirty] = useState(false);
    const [phoneDirty, setPhoneDirty] = useState(false);
    const [mailDirty, setMailDirty] = useState(false);
    const [open, setOpen] = useState(props.show);
    const closeModal = () => setOpen(false);

    const regExpPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,18}(\s*)?$/gm;

    const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setName(target.value);

        if (!target.value.match(/^[a-zA-Zа-яА-Я 0-9]+$/gm)) {
          setNameError('inperror');
        } else {
          setNameError('');
        }
    };
    const companyHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setCompany(target.value);

        if (!target.value.match(/^[a-zA-Zа-яА-Я 0-9]+$/gm)) {
          setCompanyError('inperror');
        } else {
          setCompanyError('');
        }
    };
    const prodHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setProd(target.value);

        if (!target.value.match(/^[a-zA-Zа-яА-Я 0-9]+$/gm)) {
          setProdError('inperror');
        } else {
          setProdError('');
        }
    };
    const phoneHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setPhone(target.value);

        if (!target.value.match(regExpPhone)) {
          setPhoneError('inperror');
        } else {
          setPhoneError('');
        }
    };
    const mailHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setMail(target.value);

        if (target.value == '') {
          setMailError('inperror');
        } else {
          setMailError('');
        }
    };

    useEffect(() => {
        if (!phoneError && phone && name && !nameError && company && !companyError && prod && !prodError &&mail && !mailError) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }, [phoneError, phone, name, nameError, company, companyError, prod, prodError, mail, mailError]);

    const blurHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        switch (target.name) {
          case 'phone':
            setPhoneDirty(true);
            break;
          case 'mail':
            setMailDirty(true);
            break;
          case 'name':
            setNameDirty(true);
            break;
          case 'company':
            setCompanyDirty(true);
            break;
          case 'prod':
            setProdDirty(true);
            break;
          default:
            setPhoneDirty(true);
        }
      };

    function sendForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setDisabled(true);

        fetch(`/sendmail/index.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({ phone, mail, company, name, prod }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Error occurred!')
            }
            else
            {
              setDisabled(false);
              setPhone('');
              setMail('');
              setCompany('');
              setName('');
              setProd('');
              message.success({
                content: 'Отправлено',
              });
              props.onChange();
            }
          })
          .catch(() => {
            setDisabled(false);
            message.error({
              content: 'Произошла ошибка',
            });
          });
    }


    return (
                        <form onSubmit={ sendForm }>
                            <div className='modal-partners-form order-form'>
                                <h2>Оформить заказ</h2>
                                <input placeholder = "ФИО"
                                    className={ `${ nameDirty && nameError }` }
                                    value={ name }
                                    onBlur={ blurHandler }
                                    onInput={ nameHandler }
                                    name="name"
                                    type="text" />
                                <input placeholder='Наименование организации' 
                                    className={ `${ companyDirty && companyError }` }
                                    value={ company }
                                    onBlur={ blurHandler }
                                    onInput={ companyHandler }
                                    name="company"
                                    type="text" />
                                <input placeholder = "Телефон"
                                    className={ `${ phoneDirty && phoneError }` }
                                    value={ phone }
                                    onBlur={ blurHandler }
                                    onInput={ phoneHandler }
                                    name="phone"
                                    type="tel" />
                                <input 
                                    placeholder = "Email"
                                    className={ `${ mailDirty && mailError }` }
                                    value={ mail }
                                    onBlur={ blurHandler }
                                    onInput={ mailHandler }
                                    name="mail"
                                    type="email" />
                                <input 
                                    placeholder = "Необходимая продукция или вопрос"
                                    className={ `${ prodDirty && prodError }` }
                                    value={ prod }
                                    onBlur={ blurHandler }
                                    onInput={ prodHandler }
                                    name="prod"
                                    type="text" />
                                <div>
                                    <div className="buy-button-wrapper circle">
                                        <button disabled = { disabled }>Купить</button>
                                    </div>
                                </div>
                            </div>
                        </form>
    );
}

export default ModalOrder;
