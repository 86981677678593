import React from 'react';
import { Link } from 'react-scroll';
import './topSliderScreen.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BuyButton from '../Button/BuyButton';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'dots top_dots',
    autoplay: true,
    autoplaySpeed: 4000,
};
const TopSliderScreen = () => {
    return (
        <div className="top-slider-wrapper">
            <div className="container">
                <div className='buy-form'>
                    <div>
                        ЧИСТОТА <br />
                        БЕЗОПАСНОСТЬ <br />
                        ЭКОЛОГИЯ
                    </div>
                    <div className='reagents'>
                        Российские противогололедные реагенты <br />
                        нового поколения
                    </div>
                    <Link to="form" duration={500} smooth={true}>
                        <BuyButton />
                    </Link>
                </div>
            </div>
            <Slider {...settings}>
                <div className='img-container slide3'></div>
                <div className='img-container slide1'></div>
                <div className='img-container slide4'></div>
                <div className='img-container slide5'></div>
            </Slider>
        </div>
    );
}

export default TopSliderScreen;
