import React from 'react';
import './technology.css';
import PdfLogo from "../../assets/img/pdf.svg"
import Good from "../../assets/img/good.svg"
import Scheme1 from "../../assets/img/scheme1.png"
import Scheme2 from "../../assets/img/scheme2.png"
import Scheme3 from "../../assets/img/scheme3.png"
import Scheme4 from "../../assets/img/scheme4.png"
import Time from "../../assets/img/time.svg";
import Safe from "../../assets/img/safe.svg"

const Technology = () => {
    return (
        <div className="technology-wrapper">
            <div className="container">
                <a id="technology"></a>
                <div className='technology-header'>Технология применения</div>
                <div className='download-pdf'>
                        <img src={PdfLogo} alt="pdf" />
                        <p><a href="/files/Instrukciia_alliance.pdf" title="" target="_blank">Скачать инструкцию PDF (0,4 MB)</a></p>
                </div>
                <div className='good-mark svg-paragraph'>
                    <img src={Good} alt="" />
                    <p> Для обработки
                        одного квадратного метра покрытия
                        необходимо от 15 до 65г ПГМ «Ледоруб»</p>
                </div>
                <div className='scheme'>
                    <img src={Scheme1} alt="" />
                    <img src={Scheme2} alt="" />
                    <img src={Scheme3} alt="" />
                    <img src={Scheme4} alt="" />
                </div>
                <div className='scheme-adv'>
                    <div className='time svg-paragraph'>
                        <img src={Time} alt="time" />
                        <p className='little'>Время воздействия
                            на ледяную корку — от 30 до 60 минут</p>
                    </div>
                    <div className='safe svg-paragraph'>
                        <img src={Safe} alt="safe" />
                        <p className='little'>Безвреден для&nbsp;окружающей среды и&nbsp;домашних животных при&nbsp;<a href="/files/Instrukciia_alliance.pdf" title="" target="_blank">соблюдении инструкции</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Technology;
