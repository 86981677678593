import React from 'react';
import './about.css';
import Bottle from "../../assets/img/bottle.svg"
import Track from "../../assets/img/track.png"

const About = () => {
    return (
        <div className="about-wrapper" id="about">
            <div className="container">
                <div className='about-header'>О компании</div>
                <div className='about-info'>
                    <div className='about-text'>
                        <img src={Bottle} alt="bottle" />
                        <div>
                            <p>Химзавод &laquo;Альянс&raquo; &mdash; успешное, современное, высокопрофессиональное и&nbsp;динамично развивающееся предприятие,
    ориентированное на&nbsp;потребности клиентов. </p>

    <p> Основным направлением деятельности завода является производство высококачественных противогололедных
    реагентов серии &laquo;Ледоруб&raquo; собственной разработки.</p>
                        </div>
                    </div>
                    <div className='about-track-img'>
                        <img src={Track} alt="track" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
