import React from 'react';
import { useEffect, useState } from 'react';
import './checkout.css';
import Deal from "../../assets/img/deal.png"
import BuyButtonCircle from '../Button/buyButtonCircle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { message } from 'antd';

const Checkout = () => {

  const [phoneError, setPhoneError] = useState('');
  const [mailError, setMailError] = useState('');
  const [mestextError, setMestextError] = useState('');
  const [phone, setPhone] = useState('');
  const [mail, setMail] = useState('');
  const [mestext, setMestext] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [phoneDirty, setPhoneDirty] = useState(false);
  const [mailDirty, setMailDirty] = useState(false);
  const [mestextDirty, setMestextDirty] = useState(false);

  const regExpPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,18}(\s*)?$/gm;

  const phoneHandler = (e: React.FormEvent<HTMLInputElement>) => {
    let target = e.target as HTMLInputElement;
    setPhone(target.value);

    if (!target.value.match(regExpPhone)) {
      setPhoneError('inperror');
    } else {
      setPhoneError('');
    }
  };

  const mailHandler = (e: React.FormEvent<HTMLInputElement>) => {
    let target = e.target as HTMLInputElement;
    setMail(target.value);

    if (target.value == '') {
      setMailError('inperror');
    } else {
      setMailError('');
    }
  };

  const mestextHandler = (e: React.FormEvent<HTMLTextAreaElement>) => {
    let target = e.target as HTMLTextAreaElement;
    setMestext(target.value);

    if (target.value == '') {
      setMestextError('inperror');
    } else {
      setMestextError('');
    }
  };

  useEffect(() => {
    if (!phoneError && phone && mail && !mailError && mestext && !mestextError) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phoneError, phone, mail, mailError, mestext, mestextError]);

  const blurHandler = (e: React.FormEvent<HTMLInputElement>) => {
    let target = e.target as HTMLInputElement;
    switch (target.name) {
      case 'phone':
        setPhoneDirty(true);
        break;
      case 'mail':
        setMailDirty(true);
        break;
      default:
        setPhoneDirty(true);
    }
  };

  const blurTextHandler = (e: React.FormEvent<HTMLTextAreaElement>) => {
    let target = e.target as HTMLTextAreaElement;
    switch (target.name) {
      case 'mestext':
        setMestextDirty(true);
        break;
      default:
        setPhoneDirty(true);
    }
  };

  function sendForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setDisabled(true);

    fetch(`/sendmail/index.php`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({ phone, mail, mestext }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error occurred!')
        }
        else
        {
          setDisabled(false);
          setPhone('');
          setMail('');
          setMestext('');
          message.success({
            content: 'Отправлено',
          });
        }
      })
      .catch(() => {
        setDisabled(false);
        message.error({
          content: 'Произошла ошибка',
        });
      });
  }

    return (
        <div className="checkout-wrapper" id="form">
          <div className="container">
              <div className="checkout-header">
                  <h2>Оформить заказ</h2>
                  <p>Если вы хотите оформить заказ или запросить изготовление продукции по вашему техническому заданию,
                      заполните форму ниже или свяжитесь с нами по электронной почте <a href="mailto:hza2009@mail.ru">hza2009@mail.ru</a>.</p>
              </div>
              <form onSubmit={ sendForm }>
                  <div className="checkout-form">
                      <div className='checkout-form-img-left'>
                          <img src={Deal} alt="deal" className='deal-img' />
                      </div>
                      <div className='checkout-form-field'>
                          <input 
                              placeholder = "Телефон"
                              className={ `${ phoneDirty && phoneError }` }
                              value={ phone }
                              onBlur={ blurHandler }
                              onInput={ phoneHandler }
                              name="phone"
                              type="tel"
                          />
                          <input 
                              placeholder = "Email"
                              className={ `${ mailDirty && mailError }` }
                              value={ mail }
                              onBlur={ blurHandler }
                              onInput={ mailHandler }
                              name="mail"
                              type="email"
                          />
                          <textarea className={ `${ mestextDirty && mestextError }` } placeholder='Необходимая продукция или вопрос' onBlur={ blurTextHandler }
                              onInput={ mestextHandler } name="mestext" value={ mestext }>{ mestext }</textarea>
                      </div>
                      <div className='button-buy-checkout'>
                          <div className="buy-button-wrapper circle">
                              <button disabled = { disabled }>Купить</button>
                          </div>
                          {/*<Popup trigger={<div onClick={() => (console.log(""))}> <BuyButtonCircle text='Купить' /> </div>} modal nested>
                              <div className='modal-partners-form'>
                                  <h2>Оформить заказ</h2>
                                  <input type="text" name="" id="" placeholder='ФИО' />
                                  <input type="text" name="" id="" placeholder='Наименование организации' />
                                  <input type="email" name="" id="" placeholder='Телефон' />
                                  <input type="tel" name="" id="" placeholder='E-mail' />
                                  <input type="text" name="" id="" placeholder='Необходимая продукция или вопрос' />
                                  <div>
                                      <BuyButtonCircle text='Купить' />
                                  </div>
                              </div>
                          </Popup>*/}
                      </div>
                  </div>
              </form>
            </div>
        </div>
    );
}

export default Checkout;
