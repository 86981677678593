import React from 'react';
import './comparison.css';
import { Link } from 'react-scroll';
import Table from "../../assets/img/table.svg"
import PdfLogo from "../../assets/img/pdf.svg"
import BuyButtonCircle from '../Button/buyButtonCircle';

const Comparison = () => {
    return (
        <div className="comparison-wrapper">
            <div className="container">
                <a id="comprasion"></a>
                <div className='comparison-header'>Сравнение материалов</div>
                <img src={Table} alt="" className='table-img' />
                <div className='download-pdf-com'>
                    <div className='logo-paragraph'>
                        <img src={PdfLogo} alt="pdf" />
                        <p><a href="/files/Compare_alliance.pdf" title="" target="_blank">Скачать PDF (0,4 MB)</a></p>
                    </div>
                    <Link to="form" duration={500} smooth={true}>
                        <BuyButtonCircle text='Купить' />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Comparison;
