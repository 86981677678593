import React, {useState} from 'react';
import './product.css';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Car from "../../assets/img/car-icon.svg"
import Car2 from "../../assets/img/car2-icon.svg"
import EcoIcon from "../../assets/img/eco-icon.svg"
import ManSnow from "../../assets/img/man-snow-icon.svg"
import Snow from "../../assets/img/snow-icon.svg"
import BuyButton from '../Button/BuyButton';
import BuyButtonCircleDiv from '../Button/buyButtonCircleDiv';
import ModalOrder from './ModalOrder/ModalOrder';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export interface Props {
    product: Product;
}

interface Product {
    name: string,
    id: number,
    icons: Icons,
    compound: string,
    compound2: string,
    compound3: string,
    img: string,
    text: string,
    text2: string
}

interface Icons {
    snow: boolean,
    people: boolean,
    car: boolean,
    car2: boolean,
    eco: boolean
}

const ProductComponents = (props: Props) => {
    const product = props.product;
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => { setOpen(false); setOpenModal(false);}
    return (
        <div className="product-wrapper">
            <div className='info-product-card'>
                <img src={product.img} alt="product-img"/>
                <h2>{product.name}</h2>
                <span>
                    <img src={product.icons.snow ? Snow : ''} alt="" />
                    <img src={product.icons.people ? ManSnow : ''} alt="" />
                    <img src={product.icons.car ? Car : ''} alt="" />
                    <img src={product.icons.car2 ? Car2 : ''} alt="" />
                    <img src={product.icons.eco ? EcoIcon : ''} alt="" />
                </span>
                <p>{product.compound}{product.compound2 == '' ? '' : [<br/>, product.compound2]}{product.compound3 == '' ? '' : [<br/>, product.compound3]}</p>
            </div>
            <div className='info-product-bot'>
                <span className='price-product-card'>Цена по запросу</span>
                <div className='button-buy-product'>
                    <div className="buy-button-wrapper2" onClick={() => setOpen(o => !o)}><button>Купить</button></div>
                    <Popup onClose={closeModal} open={open} className="product-modal">
                        <div className={openModal ? 'modal-product' : 'modal-product active'}>
                            <SimpleBar style={{ maxHeight: 450 }} autoHide={false}>
                                <div className="modal-product-wr">
                                    <h2>{product.name}</h2>
                                    <div className="modal-product-descr">
                                        <p>{product.text}</p>
                                        {product.text2 == '' ? '' : [<p>{product.text2}</p>]}
                                    </div>
                                    <div className = 'modal-product-bot'>
                                        <div className="charact">
                                          <h2>Основные технические показатели</h2>
                                          <ul>
                                            <li>
                                              <div>Внешний вид</div>
                                              <div></div>
                                              <div>Кристаллы, гранулы</div>
                                            </li>
                                            <li>
                                              <div>Запах</div>
                                              <div></div>
                                              <div>Отсутствует</div>
                                            </li>
                                            <li>
                                              <div>Цвет</div>
                                              <div></div>
                                              <div>От белого до светло-серого и светло-коричневого</div>
                                            </li>
                                          </ul>
                                        </div>
                                        <img src={product.img} alt="product-img"/>                               
                                    </div>
                                    <div className="modal-product-buy"><div onClick={() => { setOpenModal(true) }}><BuyButtonCircleDiv text='Купить' /></div></div>
                                </div>
                            </SimpleBar>
                        </div>
                        {openModal && <SimpleBar  style={{ maxHeight: 450 }} autoHide={false}><ModalOrder prodname={product.name} show={true} openModal={openModal} onChange={closeModal}/></SimpleBar>}
                    </Popup>    
                </div>
            </div>
        </div>
    );
}

export default ProductComponents;
