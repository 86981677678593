import React, { useState, useEffect } from 'react';
import './advantage.css';
import '../TopSliderScreen/topSliderScreen.css';
import Slider from "react-slick";
import Adv1 from "../../assets/img/adv1.jpg";
import Adv2 from "../../assets/img/adv2.jpg";
import Adv3 from "../../assets/img/adv3.jpg";
import Adv4 from "../../assets/img/adv4.jpg";



const Advantage = () => {
    const [isMobile, setMobile] = useState(window.outerWidth > 600);

    const updateMedia = () => {
        setMobile(window.outerWidth < 600);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: 'graydots',
        responsive: [
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        }
      ]
    };
    return (
        <div className="advantage-wrapper" id="advantage">
            <div className="container">
                <p className="advantage-header">Преимущества</p>
                <Slider {...settings}>
                    <div className='img-adv'>
                        <h2>Универсальность</h2>
                        <p className='description'>ЛЕДОРУБ подходит как для уборки лопатами на&nbsp;малых территориях, так и&nbsp;для обработки промышленных площадей и&nbsp;территорий с&nbsp;твердым покрытием</p>
                        <img src={Adv1} alt="image1" />
                    </div>
                    <div className='img-adv'>
                        <h2>Эффективность</h2>
                        <p className='description'>ЛЕДОРУБ уверенно работает при температуре до &minus;25&nbsp;C</p>
                        <img src={Adv2} alt="image2" />
                    </div>
                    <div className='img-adv'>
                        <h2>Безопасность</h2>
                        <p className='description'>При использовании по&nbsp;техническому регламенту реагенты ЛЕДОРУБ совершенно безопасны и&nbsp;соответствуют всем необходимым стандартам</p>
                        <img src={Adv3} alt="image3" />
                    </div>
                    <div className='img-adv'>
                        <h2>Надежность</h2>
                        <p className='description'>ЛЕДОРУБ имеет все необходимые сертификаты и&nbsp;разрешения, соответствует системе сертификации Госстандарта России &laquo;РОСТЕСТ&raquo;</p>
                        <img src={Adv4} alt="image4" />
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Advantage;
