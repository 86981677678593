import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';
import LogoGreen from "../../assets/img/logo-green.svg";
import Phone from "../../assets/img/phone.svg";
import Mail from "../../assets/img/mail.svg"

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className="container">
                <a id="footer"></a>
                <div className='bottom-menu'>
                    <div className='item-menu-container'>
                        <img src={LogoGreen} alt="logo-green" />
                        <div className="item-menu-bottom">
                            <Link to="table" duration={500} smooth={true}>Каталог</Link>
                        </div>
                        <div className="item-menu-bottom">
                            <Link to="about" duration={500} smooth={true}>О компании</Link>
                        </div>
                        <div className="item-menu-bottom">
                            <Link to="quality" duration={500} smooth={true}>Продукция</Link>
                        </div>
                        <div className="item-menu-bottom">
                            <Link to="advantage" duration={500} smooth={true}>Преимущества</Link>
                        </div>
                        <div className="item-menu-bottom">
                            <Link to="form" duration={500} smooth={true}>Оформить заказ</Link>
                        </div>
                        <div className="item-menu-bottom">
                            <Link to="partners" duration={500} smooth={true}>Партнёрам</Link>
                        </div>
                    </div>
                    <div className='adress-container'>
                        <div className='adress-item'>
                            <span>г. Пермь, 614033</span>
                            <span>ул. Лодыгина, 9</span>
                        </div>
                        <div className='contacts-items'>
                            <div>
                                <img src={Phone} alt="phone" className='icon-menu' />
                                <p><a href="tel:+73422429942">(342) 242-99-42</a></p>
                            </div>
                            <div>
                                <img src={Mail} alt="mail" className='icon-menu' />
                                <p><a href="mailto:hza2009@mail.ru">hza2009@mail.ru</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bot-wrapper">
                <div className="container">
                    <div className='copyright'>
                        <span>© ООО &laquo;Химзавод &laquo;Альянс&raquo;</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
