import React from 'react';
import { useEffect, useState } from 'react';
import './partners.css';
import OneCart from "../../assets/img/one-cart.svg"
import TwoCart from "../../assets/img/two-cart.svg"
import TrheeCart from "../../assets/img/three-cart.svg"
import FourCart from "../../assets/img/four-cart.svg"
import FiveCart from "../../assets/img/five-cart.svg"
import SixCart from "../../assets/img/six-cart.svg"
import SevenCart from "../../assets/img/seven-cart.svg"
import BuyButtonCircle from '../Button/buyButtonCircle';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { message } from 'antd';

const Partners = () => {
    const [nameError, setNameError] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [mailError, setMailError] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [nameDirty, setNameDirty] = useState(false);
    const [companyDirty, setCompanyDirty] = useState(false);
    const [phoneDirty, setPhoneDirty] = useState(false);
    const [mailDirty, setMailDirty] = useState(false);
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    const regExpPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,18}(\s*)?$/gm;

    const nameHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setName(target.value);

        if (!target.value.match(/^[a-zA-Zа-яА-Я 0-9]+$/gm)) {
          setNameError('inperror');
        } else {
          setNameError('');
        }
    };
    const companyHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setCompany(target.value);

        if (!target.value.match(/^[a-zA-Zа-яА-Я 0-9]+$/gm)) {
          setCompanyError('inperror');
        } else {
          setCompanyError('');
        }
    };
    const phoneHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setPhone(target.value);

        if (!target.value.match(regExpPhone)) {
          setPhoneError('inperror');
        } else {
          setPhoneError('');
        }
    };
    const mailHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setMail(target.value);

        if (target.value == '') {
          setMailError('inperror');
        } else {
          setMailError('');
        }
    };

    useEffect(() => {
        if (!phoneError && phone && name && !nameError && company && !companyError && mail && !mailError) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }, [phoneError, phone, name, nameError, company, companyError, mail, mailError]);

    const blurHandler = (e: React.FormEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        switch (target.name) {
          case 'phone':
            setPhoneDirty(true);
            break;
          case 'mail':
            setMailDirty(true);
            break;
          case 'name':
            setNameDirty(true);
            break;
          case 'company':
            setCompanyDirty(true);
            break;
          default:
            setPhoneDirty(true);
        }
      };

    function sendForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setDisabled(true);

        fetch(`/sendmail/index.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify({ phone, mail, company, name }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Error occurred!')
            }
            else
            {
              setDisabled(false);
              setPhone('');
              setMail('');
              setCompany('');
              setName('');
              message.success({
                content: 'Отправлено',
              });
              setOpen(false);
            }
          })
          .catch(() => {
            setDisabled(false);
            message.error({
              content: 'Произошла ошибка',
            });
          });
    }

    return (
        <div className="partners-wrapper" id="partners">
            <div className="container">
                <div className='partners-header'>
                    <h2>Партнёрам</h2>
                    <p>Приглашаем к сотрудничеству индивидуальных предпринимателей и юридические лица.</p>
                </div>
                <div className='partners-table'>
                    <div>
                        <div className="item-number">
                            <img src={OneCart} alt="" />
                            <p>Индивидуальные условия</p>
                        </div>
                        <div className="item-number">
                            <img src={TwoCart} alt="" />
                            <p>Гибкое ценообразование</p>
                        </div>
                        <div className="item-number">
                            <img src={TrheeCart} alt="" />
                            <p>Персональный менеджер</p>
                        </div>
                        <div className="item-number">
                            <img src={FourCart} alt="" />
                            <p>Собственная логистика</p>
                        </div>
                    </div>
                    <div>
                        <div className="item-number">
                            <img src={FiveCart} alt="" />
                            <p>Отсрочка платежа</p>
                        </div>
                        <div className="item-number">
                            <img src={SixCart} alt="" />
                            <p>Сопровождение продаж в регионах РФ
                                и странах СНГ</p>
                        </div>
                        <div className="item-number">
                            <img src={SevenCart} alt="" />
                            <p>Предоставление бесплатных образцов</p>
                        </div>
                    </div>
                </div>
                <div className='button-partners'>
                    <div onClick={() => setOpen(o => !o)}> <BuyButtonCircle text='Стать партнёром' /> </div>
                    <Popup onClose={closeModal} open={open} closeOnDocumentClick >
                        <form onSubmit={ sendForm }>
                            <div className='modal-partners-form'>
                                <h2>Стать партнёром</h2>
                                <input placeholder = "ФИО"
                                    className={ `${ nameDirty && nameError }` }
                                    value={ name }
                                    onBlur={ blurHandler }
                                    onInput={ nameHandler }
                                    name="name"
                                    type="text" />
                                <input placeholder='Наименование организации' 
                                    className={ `${ companyDirty && companyError }` }
                                    value={ company }
                                    onBlur={ blurHandler }
                                    onInput={ companyHandler }
                                    name="company"
                                    type="text" />
                                <input placeholder = "Телефон"
                                    className={ `${ phoneDirty && phoneError }` }
                                    value={ phone }
                                    onBlur={ blurHandler }
                                    onInput={ phoneHandler }
                                    name="phone"
                                    type="tel" />
                                <input 
                                    placeholder = "Email"
                                    className={ `${ mailDirty && mailError }` }
                                    value={ mail }
                                    onBlur={ blurHandler }
                                    onInput={ mailHandler }
                                    name="mail"
                                    type="email" />
                                <div>
                                    {/*<BuyButtonCircle text='Стать партнёром' />*/}
                                    <div className="buy-button-wrapper circle">
                                        <button disabled = { disabled }>Отправить</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Popup>
                </div>                
            </div>
        </div>
    );
}

export default Partners;
